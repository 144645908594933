<template>
    <div class="home">
        <div class="home-feed">
            <LiveFeed />
            <!-- <Chat /> -->
        </div>

        <div class="home-calendar">
            <div class="section-header"><h1>Calendar</h1></div>
            <div class="container-calendar">
                <CalendarFeed/>
            </div>
        </div>
        
        <div class="home-products">
            <ProductFeed/>
            <ExhibitorFeed/>
        </div>
    </div>
</template>
<script>
import LiveFeed from '@/components/LiveFeed';
// import Chat from '@/components/Chat';

import ProductFeed from '@/components/ProductFeed';
import ExhibitorFeed from '@/components/ExhibitorFeed';
import CalendarFeed from '@/components/CalendarFeed.vue';

export default {
    components: {
        LiveFeed,
        // Chat,
        ProductFeed,
        ExhibitorFeed,
        CalendarFeed
    }
}
</script>