<template>
    <div class="exhibitor-feed-wrap">
        <SectionHeader v-bind:title="title" v-bind:link="link"/>

        <input v-on:input="search($event.target.value)" type="text" name="search" id="search" placeholder="SEARCH">
        <img class="icon-search" src="../assets/img/icon_search.svg">

        <div v-if="isFetching" class="lds-ring">
            <img class="loading-gif" src="../assets/img/loadingwheel.gif" alt="">
        </div>
        <div v-else class="wrap">
            <div v-if="!isSearching" class="exhibitor-feed custom-scrollbar">
                <div class="exhibitor" v-for="(exhibitor, item) in this.exhibitors" v-bind:key="item">
                    <div class="col">
                            <img v-if="exhibitor.logo"  @click="goToExhibitor(exhibitor.slug, exhibitor.exhibitorId)" :src="exhibitor.logo.fluid.src" alt="exhibitor">
                            <img v-else  @click="goToExhibitor(exhibitor.slug, exhibitor.exhibitorId)" :src="exhibitor.mainImage.fluid.src" alt="exhibitor">
                    </div>
                    <div class="col">
                        <h2 @click="goToExhibitor(exhibitor.slug, exhibitor.exhibitorId)" class="exhibitor-title">{{ exhibitor.name }}</h2>

                        <p class="exhibitor-desc">{{shorten(exhibitor.shortDescription, 130, true)}}</p>
                        <a @click="goToExhibitor(exhibitor.slug, exhibitor.exhibitorId)">READ MORE</a>
                    </div>
                </div>
            </div>

            <div v-else class="exhibitor-feed custom-scrollbar">
                <div class="exhibitor" v-for="(exhibitor, item) in this.results" v-bind:key="item">
                    <div class="col">
                            <img v-if="exhibitor.logo"  @click="goToExhibitor(exhibitor.slug, exhibitor.exhibitorId)" :src="exhibitor.logo.fluid.src" alt="exhibitor">
                            <img v-else  @click="goToExhibitor(exhibitor.slug, exhibitor.exhibitorId)" :src="exhibitor.mainImage.fluid.src" alt="exhibitor">
                    </div>
                    <div class="col">
                        <h2 @click="goToExhibitor(exhibitor.slug, exhibitor.exhibitorId)" class="exhibitor-title">{{ exhibitor.name }}</h2>

                        <p class="exhibitor-desc">{{shorten(exhibitor.shortDescription, 130, true)}}</p>
                        <a @click="goToExhibitor(exhibitor.slug, exhibitor.exhibitorId)">READ MORE</a>
                    </div>
                </div>

                <div v-if="noResults && allLoadedSearch" class="no-results">
                    <p>NO RESULTS FOUND</p>
                </div>
            </div>

            <div class="load-more">
                <router-link :to="{ name: 'importers' }">
                    <p>SEE MORE</p>
                </router-link>
            </div>
        </div>

    </div>
</template>
<script>
import SectionHeader from '@/components/snippets/SectionHeader.vue'
import gql from 'graphql-tag'
import Fuse from 'fuse.js'

// const increment = 8

export default {
    name: 'ExhibitorFeed',
    components: { 
        SectionHeader,
    },

    data() {
        return {
            title: "Importers",
            link: "importers",
            itemsPerPage: 8,
            itemsPerPageSearch: 8,
            isFetching: true,
            isSearching: false,
            fetched: false,
            searched: false,
            allLoaded: false,
            allLoadedSearch: false,
            noResults: false,
            exhibitors: [],
            exhibitorsAll: [],
            results: [],
            resultsAll: []
        }
    },

    methods: {
        goToExhibitor(slug, exhibitorId) {
            this.$router.push({name:'exhibitor', params:{slug:slug, exhibitorId:exhibitorId}})
        },
        shorten(str, n, useWordBoundary) {
            if (str.length <= n) { return str; }
            const subString = str.substr(0, n-1);
            return (useWordBoundary 
                ? subString.substr(0, subString.lastIndexOf(" ")) 
                : subString) + "..."
        },
        search(string) {
            console.log("Searching");

            this.allLoadedSearch = false;
            this.$data.noResults = false

            if (string == "") {
                this.isSearching = false
                return false
            }

            var options;

            if (!isNaN(string)) {
                options = {
                    includeScore: true,
                    threshold: 0.0,
                    keys: [
                        'vintage'
                    ]
                }
            } else {
                options = {
                    includeScore: true,
                    threshold: 0.3,
                    keys: [
                        'name', 
                        {
                            name: 'city',
                            weight: 0.5
                        },
                        {
                            name: 'country',
                            weight: 0.5
                        },
                        {
                            name: 'shortDescription',
                            weight: 0.2
                        }
                    ]
                }
            }

            this.isSearching = true


            const fuse = new Fuse(this.$data.exhibitorsAll, options)
            const result = fuse.search(string)

            this.$data.results = []
            this.$data.resultsAll = []

            for (let i = 0; i < result.length; i++) {
                const obj = result[i];

                if (obj.item.bottleImage !== null) {
                    this.$data.resultsAll.push(obj.item)
                }
            }

            if (this.$data.resultsAll.length == 0) {
                this.$data.noResults = true
                this.$data.allLoadedSearch = true
            }

            if (this.$data.itemsPerPageSearch >= this.$data.resultsAll.length) {
                this.$data.allLoadedSearch = true;
            }

            this.$data.results = this.$data.resultsAll.splice(0, 8)
        
        }
    },

    apollo: {

        exhibitors: {
            query: gql`query {
                event(eventId: "5f842ed57507225ccb782eb4") {
                    exhibitors {
                        nodes {
                            mainImage {
                                fluid(maxWidth: 260, maxHeight:260, crop: FILL){
                                    src
                                }
                            }
                            logo {
                                fluid(maxWidth: 260, maxHeight:260, crop: PAD){
                                    src
                                }
                            }
                            name
                            shortDescription
                            city
                            country
                            slug
                            exhibitorId
                        }
                    }
                }
            }`,

            variables() {
                return {
                    itemsPerPage: this.itemsPerPage
                }
            },

            result ({ data, loading }) {

                if (!loading) {
                    var items = data.event.exhibitors.nodes

                    /* Randomize array in-place using Durstenfeld shuffle algorithm */
                    for (var i = items.length - 1; i > 0; i--) {
                        var j = Math.floor(Math.random() * (i + 1));
                        var temp = items[i];
                        items[i] = items[j];
                        items[j] = temp;
                    }

                    items = items.filter(function( obj ) {
                        return obj.logo !== null;
                    });

                    items = items.filter(function( obj ) {
                        return obj.shortDescription !== null;
                    });

                    this.exhibitorsAll = data.event.exhibitors.nodes
                    this.exhibitors = items.slice(0, 8)
                    this.isFetching = false
                    this.fetched = true
                }
            },
        }

    }
    

}
</script>