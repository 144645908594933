<template>
    <div class="live-feed">
        <SectionHeader v-bind:title="title"/>
        <EventFeed/>
    </div>
</template>
<script>
import SectionHeader from '@/components/snippets/SectionHeader.vue'
import EventFeed from '@/components/snippets/EventFeed.vue'


export default {
    name: 'LiveFeed',
    components: { 
        SectionHeader,
        EventFeed
    },

    data() {
        return {
            title: "Live Feed"
        }
    },
    

}
</script>