<template>
    <div class="feed-wrap">
        <div v-if="this.isFetching == false" class="event-feed custom-scrollbar">
            <div class="event" v-for="(entry, item) in this.entriesFetched" v-bind:key="item">
                <div class="col">
                    <div v-if="entry.host[0]">
                        <img v-if="entry.host[0] && entry.host[0].profilePicture.length > 0" class="event-pfp" :src="entry.host[0].profilePicture[0].url" alt="profile">
                        <p v-else class="pfp-initials unselectable">{{ getInitials(entry.host[0].fullName) }}</p>
                    </div>
                </div>
                <div class="col col-info">
                    <h2 class="event-title">{{entry.title}}</h2>
                    <h2 class="event-time"> {{entry.startTime}}</h2>
                    <h2 v-if="entry.host[0]" class="event-host">{{entry.host[0].fullName}}</h2>
                    <VideoPlayer v-if="entry.videoEmbed" :vidId="item" :videoLink="entry.videoEmbed" :thumbnail="entry.thumbnail[0].url"/>
                    <img class="event-img" v-if="entry.thumbnail[0] && !entry.videoEmbed" :src="entry.thumbnail[0].url"/>
                    <div class="sub-col">
                        <p class="event-desc" v-html="entry.description"></p>
                        <img class="icon-dropdown" src="../../assets/img/icon-dropdown.svg" @click="$event.target.parentElement.parentElement.classList.toggle('show-more')">
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="lds-ring">
            <img class="loading-gif" src="../../assets/img/loadingwheel.gif" alt="">
        </div>
    </div>

</template>

<script>
import VideoPlayer from '@/components/snippets/VideoPlayer.vue'
import gql from 'graphql-tag'
import moment from 'moment'


export default {
    name: 'EventFeed',

    components: {
        VideoPlayer
    },

    data() {
        return {
            isFetching: true,
            entriesFetched: []
        }
    },

    methods: {
        getInitials(name) {
            let rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');

            let initials = [...name.matchAll(rgx)] || [];

            initials = (
            (initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')
            ).toUpperCase();

            return initials
        }
    },

    mounted() {
        console.log("events mounted");
        this.$apollo.queries.product.skip = false

        var query = this.$apollo.queries

        setInterval(function() {
            query.product.refresh()
        }, 60 * 1000);
    },

    apollo: {

        product: {
            client: 'craft',
            query: gql`query {
                entries {
                    ... on essential2021_essential2021_Entry {
                        id
                        slug
                        title
                        description
                        startTime
                        videoEmbed
                        thumbnail {
                            url(width: 1112)
                        }
                        host {
                            ... on User {
                                id
                                firstName
                                lastName
                                fullName
                                profilePicture {
                                    ... on user_Asset {
                                    id
                                    url
                                    }
                                }
                            }
                        }
                    }
                }
            }`,

            // pollInterval: 60000, //ms
            fetchPolicy: 'network-only',

            skip () {
                console.log("skipping");
                return this.skipQuery
            },

            result ({ data, loading }) {
                console.log("Results");

                if (!loading) {
                    var entriesArray    = []
                    entriesArray        = data.entries

                    entriesArray.sort((a, b) => moment(b.startTime) - moment(a.startTime))

                    entriesArray = entriesArray.filter(function( entry ) {
                        let date = entry.startTime;
                        
                        entry.description = entry.description.replace(/<(?!a\s*\/?)[^>]+>/g, '')
                        
                        if (!moment(date).isValid()) {
                            return true
                        }

                        else if (moment(date) < moment()) {
                            return true
                        } 
                        
                        else {
                            return false
                        }
                    });

                    for (let index = 0; index < entriesArray.length; index++) {
                        const entry = entriesArray[index];
                        let date = entry.startTime;

                        if (moment(date) < moment()) {
                            entry.startTime = moment(date).format('h:mm a dddd')
                        }
                    }

                    this.entriesFetched = entriesArray
                    this.isFetching = false;
                }
            },
        }
    }
}
</script>